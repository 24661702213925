@import './colors';

.color_app {
  color: @app_text_color;
  &:after{
    color: @app_text_color;
  }
}

.app_medium_color {
  color: @app_medium_color;
  &:after{
    color: @app_medium_color;
  }
}

.app_success_color {
  color: @app_success_color;
  &:after{
    color: @app_success_color;
  }
}

.app_warning_color {
  color: @app_warning_color;
  &:after{
    color: @app_warning_color;
  }
}

.color_app__icon {
  &:after{
    background-color: @app_text_color;
  }
}

.color_app_success__icon {
  &:after{
    background-color: @app_success_color;
  }
}

.color_divider_app__icon {
  &:after{
    background-color: @app_medium_color;
  }
}

.color_app__primary {
  color: @app_primary_color;
  &:after{
    color: @app_primary_color;
  }
}

.color_app__primary_hover {
  &:hover, &:focus, &:active {
    color: @app_primary_color;

    &:after {
      color: @app_primary_color;
    }
  }
}

.cart__popover {
  position: absolute;
  width: 25rem;
  padding: 1.375rem;
  padding-right: 0;
  box-shadow: 0 0 19px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
  background: white;
  top: 5rem;
  right: 5rem;
  h5 {
    font-weight: 700;
  }

  .cart__table_wrapper {
    padding-right: 1.375rem;
    max-height: 15rem;
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 0.8125rem;
    .cart__table_row {
      border-bottom: 1px solid @app_divider_color;
      display: flex;
      flex-direction: column;
      .cart__table_row_item_wrapper {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;
        .cart__table_row_subitems {
          display: flex;
          justify-content: space-between;
        }
      }
      .cart__table_row_subitems_wrapper {
        .cart__table_subitem_wrap {
          list-style: none;
          display: flex;
          flex-direction: column;
          padding: 0 3rem;
          margin: 0;
          margin-bottom: 0.25rem;
          li {
            color: @app_medium_color;
            margin-bottom: 0.25rem;
            display: flex;
            justify-content: space-between;
          }
        }

      }
    }
  }
  .cart_summary {
    font-size: 0.8125rem;
    display: flex;
    justify-content: space-between;
    padding-right: 1.375rem
  }
  .cart__checkout__box {
    padding-right: 1.375rem;
  }
}

.app__main {
  min-height: 60vh;
  padding: 2rem;
}

.menu__wrapper {
  .menu__delivery_price_wrap {
    margin-bottom: 1rem;
    span {
      font-weight: 600;
    }
  }
  .menu__name_wrap {
    margin-bottom: 1.25rem;
  }
  .menu__delivery_data_wrap {
    display: flex;
    margin-bottom: 1rem;
    .data_part {
      color: @app_lighter_color;
      text-transform: uppercase;
      height: 1rem;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      .coco__icon {
        margin-right: 0.25rem;
      }
      span {
        font-size: 0.875rem;
      }
    }
  }
  .restaurant__logo__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 7rem;
      max-width: 9rem;
    }
  }
  .menu__category_header {
    margin-bottom: 1rem;
    font-weight: 600;
  }
  .menu__card {
    border: 1px solid @app_divider_color;
  }
  .menu__card__wrapper {
    &:nth-child(odd) {
      margin-right: 2rem;
      margin-bottom: 1.5rem;
    }
    display: flex;
    .menu__card_wrap {
      cursor: pointer;
      transition: background-color .2s linear !important;
      display: flex;
      &.card_info {
        padding: 1.3125rem;
        flex-direction: column;
        flex: 8;
        flex-basis: 75%;
        h3 {
          font-weight: 600;
        }
        .card_info__decs {
          min-height: 2rem;
          max-width: 20rem;
        }
      }
      &.card_image {
        flex: 2;
        flex-basis: 35%;
        .image_wrapper {
          width: 100%;
          background-size: cover;
          background-position: center;
          position: relative;
          .add_btn {
            visibility: hidden;
            opacity: 0;
            padding: 0;
            text-align: center;
            border-radius: 50%;
            position: absolute;
            left: 60%;
            top: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity 0.5s linear !important;
          }
        }
      }
    }
    &:hover {
      .menu__card_wrap {
        background-color: @app_hover_card_bg;
        transition: background-color .2s linear;
        .image_wrapper {
          .add_btn {
            visibility: visible;
            opacity: 1;
            transition: opacity .2s linear;
          }
        }
      }
    }
  }
}

.modal_ncovered {
  .modal_ncovered__boby {
    padding: 6rem 3rem 3rem;
    h3 {
      text-align: center;
    }
    .backup__partners {
      display: flex;
      .backup__partners__item {
        height: 4.5rem;
        width: 10rem;
        margin-right: 0.5rem;
        background-position: center !important;
        background-size: cover !important;
        border-radius: 20px;
      }
    }
  }
}

.modal_not_service_time {
  .modal_not_service_time__body {
    padding: 6rem 3rem 3rem;
    h3 {
      text-align: center;
    }
    .button__wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

.modal_add_product{
  .ant-modal-body {
    padding-right: 1.25rem;
  }
  .ant-modal-header {
    border: none;
  }
  .menu_option__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 1rem;
    p {
      line-height: 1;
    }
    .menu_option__item {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      p {
        line-height: 1.5;
      }
    }
  }
  textarea {
    margin-bottom: 0;
  }
  .modal__footer {
    display: flex;
    margin-bottom: 1rem;
    .action__button {
      box-sizing: content-box;
      display: flex;
      justify-content: space-between;
    }
    .count__wrapper {
      flex: 3;
    }
    .checkout__wrapper {
      flex: 10;
      display: grid;
      margin-left: 6rem;
    }
  }
}

.checkout__form {
  &.pay {
    .ant-form-item:first-child {
      height: 3rem;
    }
    .ant-form-item-control-input:first-child {
      border-bottom: 1px solid @app_divider_color;
    }
  }
  .btn__wrapper {
    .ant-form-item-control-input {
      border-color: transparent !important;
    }
  }

  .ant-form-item {
    input {
      border-bottom: 1px solid @app_divider_color;
      &.no__border {
        border-bottom: 0;
      }
    }
    margin-bottom: 2rem;
    .ant-form-item-label {
      line-height: 1;
    }
    label {
      font-size: 0.875rem;
      color: @app_medium_color;
    }
    .ant-form-item-control-input-content {
      .ant-input {
        padding: 0.5rem 0;
        font-size: 0.875rem;
        &#note {
          padding: 0.5rem;
        }
      }
      .ant-input[disabled] {
        color: @app_text_color;
      }
    }
  }
  .btn__wrapper {
    margin-bottom: 0;
  }
}

.checkout__wrapper {
  display: flex;
  .card {
    border: 1px solid @app_divider_color;
    border-radius: 3px;
  }
  .checkout__wrap{
    position: relative;
    &:first-child { flex: 15;
      margin-right: 5rem;
    }
    &:last-child { flex: 11;
      max-width: 400px;
      margin-left: auto; }
    .disable__checkout_wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffffff9e;
      z-index: 99;
      transition: all 1s;
    }
    .subtitle {
      font-weight: 600;
      margin-bottom: 1rem;
      font-size: 0.875rem; line-height: 1rem
    }
    .checkout__info_card {
      .card;
      max-width: 530px;
      padding: 2rem;
      span { font-size: 0.875rem; }
      a {
        font-size: 0.875rem;
      }
    }

    .checkout__payment_card {
      .card;
      max-width: 530px;
      padding: 2rem;
      span { font-size: 0.875rem; }
    }

    .checkout__place_btn_wrapper {
      max-width: 530px;
      padding: 2rem;
      display: flex;
      justify-content: center;
      .action__button {
        padding: 0 5rem;
        height: 3rem;
      }
    }

    .checkout__cart_card {
      border: 1px solid @app_divider_color;
      .map_wrapper {
        height: 200px;
        background: rgba(128, 128, 128, 0.31);
      }
      .checkout__cart_card__body {
        padding: 2rem;
        .header__cart {
          margin-bottom: 1rem;
          display: flex;
          justify-content: space-between;
          a {
            color: @app_primary_color;
          }
        }
        .menu_item_card__wrapper {
          font-size: 0.875rem!important;
        }
        .promo__form {
          padding: 0.5rem 0;
          border-top: solid 1px @app_divider_color;
          border-bottom: solid 1px @app_divider_color;
          display: flex;
          justify-content: space-around;
          .promo_input {
            flex: 8;
            input {
              font-size: 0.875rem;
            }
          }
          .promo_action {
            flex: 2;
            display: flex;
            align-items: center;
            a {
              font-size: 0.875rem;
              color: @app_medium_color;
              &.active { color: @app_primary_color; }
            }
          }
        }
        .cart_subtotal__wrapper {
          padding: 2rem 0;
          border-bottom: 1px solid @app_divider_color;
        }
        .cart_total__wrapper {
          padding-top: 1.25rem;
          span {
            font-weight: 700;
          }
        }
        .cart_subtotal__wrapper, .cart_total__wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .cart_subtotal__wrap, .cart_total__wrap {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.6875rem;
            line-height: 2;
          }
        }
      }
      .cart_subtotal_discount {
        color: #F95946
      }
    }
  }
}

.thank__you {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 640px;
  padding: 0 2rem;
  .summary_box {
    margin-bottom: 1rem;
    padding: 0.5rem;
    background: @app_background_gradient;
    .summary_wrapper {
      h1 {
        color: @app_background_color;
        text-align: center;
        margin-bottom: 1.25rem;
      }
      p {
        font-size: 1.25rem;
        text-align: center;
        margin-bottom: 1.25rem;
      }
      padding: 2rem 5rem;
      background: @app_text_color;
      color: @app_background_color;
    }
  }
  .information__box {
    padding: 2rem;
    width: 100%;
    p {
      font-weight: 700;
      text-align: center;
      font-size: 1rem;
    }
  }
  .restaurant_data__wrapper {
    width: 100%;
    padding: 2rem 0;
    border-top: 1px solid @app_divider_color;
    border-bottom: 1px solid @app_divider_color;
    .header__wrap {
      display: flex;
      align-items: center;
    }
    .restaurant__logo__wrapper {
      display: flex;
      justify-content: flex-end;
      img {
        max-height: 3.5rem;
      }
    }
    .detail__wrapper {
      margin-top: 1.25rem;
      .detail__item {
        font-size: 0.875rem;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
      }
    }
    .cart_menu__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      .cart_menu__wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.875rem;
        line-height: 2;
        span { flex: 8; };
        span:first-child {
          flex: 1;
        }
        span:last-child {
          flex: 2;
          text-align: right;
        }
      }
    }
    .cart_subtotal__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      .cart_subtotal__wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.875rem;
        line-height: 2;
      }
    }
  }
  .need_help_wrapper {
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:hover {
        p {
          text-underline: @app_primary_color;
          color: @app_primary_color;
        }
      }
    }
  }
}

.profile_card__wrapper {
  width: 35rem;
}
