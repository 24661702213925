@media (max-width: 720px) {
  .base_landing__header {
    padding: 1rem;
    margin-top: 2rem;
    height: 2rem;
    width: 100%;

    .auth__buttons {
      display: flex;
      justify-content: space-between;
      width: 150px;
      align-items: center;

      a {
        color: @color_nav_link;
      }
    }

    img {
      max-width: 90px;
    }
  }

  .base_landing__jubmbotron {
    height: 90vh;
    background-size: cover !important;
  }

  .landing__autocomplete {
    margin-top: 2.5rem;
    input {
      font-size: 1rem;
      padding: 0.75rem 1rem;
      width: 300px !important;
    }
  }
}
