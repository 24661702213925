@import "./colors";

.center-col {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.verification__wrapper {
  width: 85%;

  .button {
    background-color: @color_action_primary;
    border-radius: 2px;
    border-width: 0;
    color: white;
    font-family: Inter;
    font-size: 12px;
    font-weight: bold;
    height: 46px;
    line-height: 15px;
    letter-spacing: 0.045em;
    margin-bottom: 1rem;
    width: 100%;

    &.all-caps {
      text-transform: uppercase;
    }

    &.link {
      background-color: @color_action_link_color;
    }
  }

  .coco-robot-container {
    display: flex;
    height: 250px;
    margin-bottom: 38px;
    margin-top: 31px;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  .end-button-container {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 36px;
    padding-top: 36px;
    width: 100%;

    .button[disabled] {
      background-color: #f5f5f5;
      color: #00000040;
      border-color: #d9d9d9;
    }
  }

  .f-2 {
    flex: 2;
  }

  .j-end {
    justify-content: flex-end;
  }

  .picker-container .picker-highlight {
    height: 56px !important;

    &::after,
    &::before {
      background-color: @app_grey_border_color;
    }
  }

  .picker-item {
    color: @app_text_disabled_color;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.015em;
    line-height: 0%;
    text-align: center;

    &.picker-item-selected {
      color: @app_text_color;
      font-size: 21px;
      font-weight: 600;
      height: 56px !important;
      line-height: 56px !important;
    }
  }

  .text {
    color: @app_text_color;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: 0.045em;

    &.centered-text {
      text-align: center;
    }

    &.grey {
      color: @app_lighter_color;
      font-weight: 500;
      line-height: 13px;
    }

    &.link {
      color: @color_action_link_color;
      font-weight: 500;
      line-height: 13px;
    }

    &.mini {
      font-size: 11px;
    }

    &.paragraph {
      font-weight: 500;
      line-height: 17px;
      margin-bottom: 19px;
    }

    &.subtitle {
      font-size: 17px;
      font-weight: 600;
      line-height: 21px;
      margin-bottom: 11px;

      &.mini {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 0;
        margin-top: 17px;

        &.bottom-spacing {
          margin-bottom: 31px;
          margin-top: 0;
        }
      }
    }

    &.title {
      font-family: FilsonPro;
      font-size: 22px;
      font-weight: bold;
      line-height: 30px;
    }
  }

  .upload__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;

    .ant-badge {
      .close {
        color: #f5222d;

        svg {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    .content {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .content-container {
        margin: auto;

        input {
          display: none;
        }

        .anticon {
          width: 100%;

          svg {
            height: 3rem;
            width: 3rem;
          }
        }
      }

      .image-container {
        background-color: @app_light_background_color;
        border-radius: 8px;
        cursor: pointer;
        padding: 20.5px 34px;

        img {
          max-width: 60px;
          object-fit: contain;
          width: 100%;
        }
      }

      .label {
        margin-top: 15px;
        max-width: 124px;
        width: 100%;

        &.p-r {
          padding-right: 6px;
        }
      }

      &.bottom-spacing {
        margin-bottom: 1rem;
      }
    }

    .preview {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      height: 150px;
      overflow: hidden;
      max-width: 100%;

      img {
        background-color: @app_light_background_color;
        border-radius: 8px;
        display: block;
        height: 100%;
        margin: auto;
        object-fit: contain;
        width: 100%;
      }

      span {
        color: @app_medium_color;
        cursor: pointer;
        line-height: 17px;
        text-decoration: underline;
      }
    }
  }
}

@primary-color: #F95946;@layout-sider-background: transparent;@layout-header-background: transparent;@layout-body-background: transparent;@layout-footer-background: transparent;