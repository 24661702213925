@import './colors';
@icon_path: '../icons';

a {
  .coco__icon {
    &:hover, &:focus, &:active {
      &:after {
        background-color: @app_primary_color;
      }
    }
  }
}

.coco__icon {
  line-height: 0;

  &:after {
    content: '';
    display: inline-block;
    height: 1.3125rem;
    width: 1.3125rem;
    background-color: @gray-600;
  }

  &.footer__icon, &.action_btn__icon {
    &:after {
      background-color: @landing_footer_icon_link;
    }
    &:hover:after, &:focus:after {
      background-color: @landing_footer_icon_link;
    }
  }

  &.md:after {
    height: 1rem;
    width: 1rem;
  }

  &.ssm:after {
    height: 0.4rem;
    width: 0.4rem;
  }

  &.sm:after {
    height: 0.75rem;
    width: 0.75rem;
  }

  &.lg:after {
    height: 1.75rem;
    width: 1.75rem;
  }

  &.s-lg:after {
    height: 2.75rem;
    width: 2.75rem;
  }

  &.facebook:after {
    mask: url('@{icon_path}/facebook.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/facebook.svg') no-repeat center center / contain;
  }

  &.twitter:after {
    mask: url('@{icon_path}/twitter.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/twitter.svg') no-repeat center center / contain;
  }

  &.instagram:after {
    mask: url('@{icon_path}/instagram.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/instagram.svg') no-repeat center center / contain;
  }

  &.location:after {
    mask: url('@{icon_path}/location.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/location.svg') no-repeat center center / contain;
  }

  &.bucket:after {
    mask: url('@{icon_path}/bucket.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/bucket.svg') no-repeat center center / contain;
  }

  &.time:after {
    mask: url('@{icon_path}/time.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/time.svg') no-repeat center center / contain;
  }

  &.plus:after {
    mask: url('@{icon_path}/plus.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/plus.svg') no-repeat center center / contain;
  }

  &.close:after {
    mask: url('@{icon_path}/close.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/close.svg') no-repeat center center / contain;
  }
  &.menu:after {
    mask: url('@{icon_path}/menu.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/menu.svg') no-repeat center center / contain;
  }
  &.google:after {
    mask: url('@{icon_path}/google.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/google.svg') no-repeat center center / contain;
  }
  &.apple:after {
    mask: url('@{icon_path}/apple.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/apple.svg') no-repeat center center / contain;
  }
  &.elipse:after {
    mask: url('@{icon_path}/elipse.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/elipse.svg') no-repeat center center / contain;
  }
  &.chat:after {
    mask: url('@{icon_path}/chat.svg') no-repeat center center / contain;
    -webkit-mask: url('@{icon_path}/chat.svg') no-repeat center center / contain;
  }
}