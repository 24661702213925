/* Numeric keyword values
font-weight:
100; 200;300;
400;// normal
500; 600;
font-weight: 700;// bold
800; 900;
*/

@path-fonts: '../fonts/Inter/static';

// START Inter
@font-face {
    font-family: "Inter";
    src: url("@{path-fonts}/Inter-Regular.ttf");
    font-weight: inherit;
    font-style: normal;
}
@font-face {
    font-family: "Inter";
    src: url("@{path-fonts}/Inter-Light.ttf");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Inter";
    src: url("@{path-fonts}/Inter-Regular.ttf");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Inter";
    src: url("@{path-fonts}/Inter-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Inter";
    src: url("@{path-fonts}/Inter-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("@{path-fonts}/Inter-ExtraBold.ttf");
    font-weight: 900;
    font-style: normal;
}
// END Inter

@filson-path-fonts: '../fonts/Filson';

// START FilsonPro
@font-face {
    font-family: "FilsonPro";
    src: url("@{filson-path-fonts}/FilsonProBlack.ttf");
    font-weight: inherit;
    font-style: normal;
}
@font-face {
    font-family: "FilsonPro";
    src: url("@{filson-path-fonts}/FilsonProLight.otf");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "FilsonPro";
    src: url("@{filson-path-fonts}/FilsonProRegular.otf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "FilsonPro";
    src: url("@{filson-path-fonts}/FilsonProMedium.otf");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "FilsonPro";
    src: url("@{filson-path-fonts}/FilsonProBold.otf");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "FilsonPro";
    src: url("@{filson-path-fonts}/FilsonProHeavy.otf");
    font-weight: 900;
    font-style: normal;
}
// END FilsonPro

// icons

