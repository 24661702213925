@import "colors";

@header_height: 5.25rem;

.auth_header {
  height: @header_height;

  .auth_header_wrapper {
    padding: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid @app_divider_color;
    .header__logo {
      img { width: 7.5rem }
    }
  }
}

.auth__wrapper {
  padding-top: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh - @header_height);
}

.auth_card__wrapper {
  width: 25rem;
}

.auth_card__wrap {
 color: white;
}

// components
.auth_card__tab {
  display: flex;
  justify-content: center;
  .auth_card__tab_wrapper {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    .auth_card__tab_wrap {
      a {
        .underline__link;
        font-weight: 700;
        font-size: 1.375rem;
        line-height: 3rem;
        padding: 0 0.5rem;
      }
    }
  }
}

.auth_btn {
  .action__button_lg; // from ui
  & {
    background: @auth_btn_google;
    border: @auth_btn_google;
    &:disabled {
      background: @auth_btn_google;
      border: @auth_btn_google;
      color: @color_action_color;
    }
  }
  &.google__btn {
    background: @auth_btn_google;
    border: @auth_btn_google;
    &:disabled {
      background: @auth_btn_google;
      border: @auth_btn_google;
      color: @color_action_color;
    }
  }
}

.auth__form {
  .ant-select-selector {
    border-radius: 5px;
  }
  &.ant-form-large .ant-form-item-label > label {
    height: 1rem;
    font-size: 0.875rem;
    font-weight: 600;
  }
  .ant-input, .ant-input-affix-wrapper {
    border-radius: 5px;
  }
  .ant-select-selector {
    border-radius: 5px;
  }
  .styles_react-code-input-container__tpiKG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%!important;
  }
  .styles_react-code-input__CRulA > input {
    font-family: 'Inter';
    font-weight: 700;
    margin: 0 3px !important;
    &:first-child, &:last-child {
      border-radius: 0!important;
      border-right: 0!important;
    }
    &:focus {
      outline: none;
      border: 0;
      caret-color: black;
    }
    border: 0;
    border-bottom: 2px solid !important;
  }
}

@media (max-width: 720px) {
  .auth_card__wrapper {
    width: 90%;
  }
  .auth_btn {
    font-size: 1rem;
    padding: 1.5rem;
    height: 4rem;
    &.social__btn {
      font-size: 1.33rem;
      span {
        font-size: 1.33rem;
      }
    }
  }
  .auth__wrapper {
    padding-top: 1.75rem;
  }
  .ant-form .ant-form-item {
    margin-bottom: 1rem;
  }
  &.ant-form-large .ant-form-item-label > label {
    height: 1rem;
    font-size: 1.25rem!important;
  }
}
