@import './colors';

@media (max-width: 720px) {
  .app__main {
    min-height: 80vh;
    padding: 0;
  }

  .mobile_delivery__header {
    padding: 0.5rem 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid @app_light_medium_color;
    span {
      color: @app_medium_color;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 1.25rem;
    }
  }

  .app_header {
    margin-bottom: 1rem;
    .app_header__wrapper {
      display: flex;
      padding: 2rem;
      justify-content: space-between;
      .header__logo {
        img {
          max-width: 6rem;
        }
      }
    }
  }

  .menu__delivery__wrapper {
    padding: 2rem;
    border-bottom: 1px solid @app_light_medium_color;
  }

  .menu__items__wrapper {
    padding: 2rem;
  }

  .category__row {
    border-bottom: 1px solid @app_divider_color;
  }

  .mobile_menu__card {
    border-bottom: 1px solid @app_divider_color;
  }

  .mobile_menu__card__wrapper {
    display: flex;
    .menu__card_wrap {
      cursor: pointer;
      transition: background-color .2s linear !important;
      display: flex;
      &.card_info {
        padding: 2rem 0;
        flex-direction: column;
        flex: 10;
        flex-basis: 85%;
        h3 {
          font-weight: 600;
        }
        .card_info__decs {
          min-height: 2rem;
          max-width: 20rem;
        }
      }
      &.card_image {
        flex: 2;
        flex-basis: 15%;
        .image_wrapper {
          width: 100%;
          background-size: cover;
          background-position: center;
          position: relative;
          .add_btn {
            background-color: @color_action_primary;
            border-color: @color_action_primary;
            padding: 0;
            text-align: center;
            border-radius: 50%;
            position: absolute;
            left: 60%;
            top: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity 0.5s linear !important;
          }
        }
      }
    }
  }

  .mobile__modal_add_product{
    .ant-modal-body {
      font-size: 1rem;
      padding-top: 5rem;
      h3 {
        padding-bottom: 2rem;
      }
    }
    .modal_add_header_block {
      border-bottom: 1px solid silver;
      margin-bottom: 1.5rem;
    }

    .menu_option__wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 38vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 1rem;
      p {
        line-height: 1;
      }
      .menu_option__item {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        &.ant-select-selection-item {
          font-size: 1rem;
        }
        p {
          line-height: 1.5;
        }
      }
    }
    .mobile_add__instruction {
      margin-bottom: 1rem;
      textarea {
        padding: 1rem;
      }
    }
    .count__wrapper {
      margin-bottom: 1rem;
      .counter {
        margin: auto;
        width: 9rem;
        .ant-btn-icon-only {
          width: 25px;
          height: 25px;
          padding: 2.4px 0;
          font-size: 16px;
          border-radius: 2px;
          vertical-align: -1px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .modal_not_service_time {
    .modal_not_service_time__body {
      padding: 2rem 0 0;
    }
  }

  .mobile__modal_add_product{
    .ant-modal-body {
      font-size: 1rem;
      padding: 1rem;
      padding-top: 3rem;
      h3 {
        padding-bottom: 2rem;
      }
    }
    .modal_add_header_block {
      border-bottom: 1px solid silver;
      margin-bottom: 1.5rem;
    }

    .menu_option__wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 38vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 1rem;
      p { line-height: 1; }
      .menu_option__item {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        &.ant-select-selection-item { font-size: 1rem; }
        p { line-height: 1.5; }
      }
    }
    .mobile_add__instruction {
      margin-bottom: 1rem;
      textarea { padding: 1rem; }
    }
    .count__wrapper {
      margin-bottom: 1rem;
      .counter {
        margin: auto;
        width: 9rem;
        .ant-btn-icon-only {
          width: 25px;
          height: 25px;
          padding: 2.4px 0;
          font-size: 16px;
          border-radius: 2px;
          vertical-align: -1px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .mobile__modal_cart {
    font-size: 1rem;
    .ant-modal-body {
      .app__scrolled;
        padding: 1.75rem;
        padding-top: 0.75rem;
        overflow-y: scroll;
        height: 80vh;
        margin-top: 1rem;
    }
    .cart_summary {
      .mt-1-25;
      display: flex;
      justify-content: space-between;
    }
  }

  .modal_ncovered {
    .modal_ncovered__boby {
      padding: 1rem;
      .backup__partners {
        display: flex;
        margin: auto;
        justify-content: center;
        .backup__partners__item {
          height: 3rem;
          width: 7rem;
          margin-right: 0.5rem;
          background-position: center !important;
          background-size: cover !important;
          border-radius: 5px;
        }
      }
    }
  }

  .checkout__wrapper {
    h1 {
      font-weight: 700;
      font-size: 1.85rem;
    }
    flex-direction: column;
    padding: 2rem 1rem;

    .checkout__wrap {
      .subtitle {
        font-size: 1.125rem;
      }
      .checkout__info_card {
        border: none !important;
        padding: 0.5rem 0 !important;
      }

      .checkout__payment_card {
        border: none;
        padding: 0.5rem 0;
      }

      .checkout__cart_card {
        border: none;
        margin-top: 2rem;
        .checkout__cart_card__body {
          padding: 0.5rem 0;
          .promo_input {
            input {
              font-size: 1rem !important;
            }
          }
          .promo_action {
            a {
              font-size: 1rem !important;
            }
          }
        }
        .cart_subtotal__wrapper {
          border: none;
        }
        .cart_total__wrapper {
          padding: 0;
        }
      }
    }
    .checkout__wrap:first-child {
      margin: 0;
    }
    .checkout__wrap:last-child {
      margin: 0;
    }
  }

  .checkout__form {
    .ant-input {
      font-size: 1rem !important;
    }
  }

  label {
    font-size: 1rem;
  }


  .profile_card__wrapper {
    width: 100%;
    padding: 1rem;
  }
}

