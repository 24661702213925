@import './colors';

h1, h2, h3, h4, h5, h6, .head__text {
  margin: 0;
  font-family: 'FilsonPro';
  span {
    font-family: 'FilsonPro';
  }
}

h1 {
  font-size: 1.5rem;
  font-family: 'FilsonPro';
  font-weight: 800;
}

.p-1 { padding: 1rem; }
.p-2 { padding: 2rem; }

.pt-0-25 { padding-top: 0.25rem }
.pb-4 { padding-bottom: 4rem; }

.mr-0-25 { margin-right: 0.25rem; }
.ml-0-25 { margin-left: 0.25rem; }

.mb-0-5 { margin-left: 0.5rem; }

.mt-1 { margin-top: 1rem; }
.mr-1 { margin-right: 1rem; }
.ml-1 { margin-left: 1rem; }
.mb-1 { margin-bottom: 1rem; }

.mt-1-75 { margin-top: 1.75rem; }
.mr-1-75 { margin-right: 1.75rem; }
.ml-1-75 { margin-left: 1.75rem; }
.mb-1-75 { margin-bottom: 1.75rem; }

.mt-1-25 { margin-top: 1.25rem; }
.mt-2 { margin-top: 2rem; }
.mb-2 { margin-bottom: 2rem; }
.mt-2-25 { margin-top: 2.25rem; }
.mr-2 { margin-right: 2rem; }
.mb-3 { margin-bottom: 3rem; }
.mt-3 { margin-top: 3rem; }
.mb-4 { margin-bottom: 4rem; }

.text-sx { font-size: 0.75rem; }
.text-sm { font-size: 0.875rem; }
.text-1x { font-size: 1rem; }
.text-1-375x { font-size: 1.375rem; }
.text-1-75x { font-size: 1.75rem; }
.text-1-5x { font-size: 1.5rem; }
.text-2x { font-size: 2rem; }
.text-3x { font-size: 3rem; }
.text-4x { font-size: 4rem; }
.text-5x { font-size: 5rem; }


.text-upper {
  text-transform: uppercase;
}

.semi-bold { font-weight: 600 };
.bold { font-weight: 700 };

.text-center { text-align: center; }


.container {
  max-width: 1200px;
  margin: auto;
}

.full {
  width: 100%;
}

// text
.app__text-upper {
  text-transform: uppercase;
}

.app__text-underline {
  text-underline: @app_text_color;
}

.app_text_medium_bg {
  padding: 0.2rem 0.5rem;
  background: @app_light_medium_color;
  color: @app_text_semi_color;
  border-radius: 3px;
}

// link
a {
 &.hover__app_color {
   &:hover, &:focus, &:active {
     color: @app_primary_color;
   }
 };
}

// scroll

.app__scrolled {
  &::-webkit-scrollbar {
    width: 0.15em;
    margin-left: 1rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @app_primary_color;
    outline: none;
  }
}

// buttons
.action__button {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 0.75rem 2rem;

  &.search, &.sign-up, &.app__btn {
    background: @color_action_primary;
    border: @color_action_primary;
    color: @color_action_color;
    &:disabled {
      background: @color_action_primary_disabled;
      border: @color_action_primary;
      color: @color_action_color;
    }
  }
}

.user_header__icon {
  cursor: pointer;
  border: 2px solid @app_primary_color;
  background: @app_background_color;
  color: @app_primary_color;;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.count__btn {
  background-color: @app_light_medium_color;
  background: @app_light_medium_color;
  border: @app_light_medium_color;
  color: @app_text_semi_color;
  &:hover, &:focus, &:active {
    color: @app_text_semi_color;
    background-color: @app_light_medium_color;
    border-color: @app_light_medium_color;
  }
}

.action__button_sm {
  .action__button; // from ui
  font-size: 0.9375rem;
  height: 2.125rem;
  padding: 0 2.5rem;
}

.action__button_md {
  .action__button; // from ui
  font-size: 0.9375rem;
  height: 3.125rem;
  padding: 0 2.5rem;
}

.action__button_lg {
  .action__button; // from ui
  height: 3.125rem;
  padding: 0.5rem 2.5rem;
}

// buttons

// links

a {
  color: @app_text_color;
  &:hover,
  &:focus,
  &:active {
    color: @app_text_color;
  }

}

.nav__link {
  color: @color_nav_link;
}

.underline__link {
  position: relative;
  &:hover,
  &:focus,
  &:active,
  &.active  {
    /* cursor: pointer;*/
    &:after {
      width: 100%;
    }
  }
  &:after {
    content: '';
    position: absolute;
    left: 0; bottom: -0.5rem;
    height: 2px;
    background-color: @app_primary_color;
    width: 0;
    transition: width .25s;
  }
  &.app__text_color:after {
    background-color: @app_text_color;
    height: 3px;
  }
}

.primary__link {
  &:hover, &:focus, &:active,
  &.active {
    color: @app_primary_color;
    transition: width .25s;
  }
  transition: width .25s;
}

// forms

.app__input {
  font-size: 0.875rem;
  border-radius: 5px;
  padding: 1rem;
}

.counter {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.non___border {
  border-color: transparent;
  padding: 0;
  &:hover, &:focus, &:active,
  &.active {
    border-color: transparent;
    box-shadow: none;
  }
}

.ant-select-arrow {
  color: @app_medium_color;
}

.app__checkbox {
  .ant-checkbox-inner {
    width: 1.5rem;
    height: 1.5rem;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      &::after {
        top: 45%;
        left: 22%;
        width: 0.5rem;
        height: 0.875rem;
        border-color: @app_primary_color;
      }
      background-color: transparent;
      border-color: @app_medium_color;
    }
    &::after {
      border: 1px solid transparent;
    }
    .ant-checkbox-inner::after {
      background-color: transparent;
    }
  }
}

// elements

.inputted__card_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span, a {
    font-size: 0.875rem;
  }
}


//Modals

.modal-menu {
  .ant-modal-close {
    top: 1rem;
    right: 4rem;
    .ant-modal-close-x {
      height: 0;
      width: 0;
      .anticon {
        font-size: 1.375rem;
      }
    }
  }
  .ant-modal-header {
    padding: 2.25rem;
    padding-bottom: 0;
  }
  .ant-modal-body {
    padding: 1.375rem 2.25rem;
  }
  .ant-modal-header {
    border: none;
  }
}


