@import "~antd/dist/antd.less";

.base_landing__layout {
  .ant-spin-nested-loading {
    background-color: black;
  }
}

html,
body {
  font-size: 16px;

  &.no-overflow {
    overflow: hidden;
  }
}

body,
html,
.app {
  height: 100%;
}

body {
  display: flex;
  font-size: 16px;
}

#root {
  display: flex;
  flex: 1;
  overflow-x: hidden;
}

input,
select {
  font-size: 100%;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
}

body,
html,
span,
p,
a {
  font-family: Inter, sans-serif;
  margin: 0;
}

span,
p {
  letter-spacing: 0.45px;
}

.ant-modal {
  top: 20%;
}

@media (max-width: 720px) {
  html,
  body {
    font-size: 13px;
  }
  span,
  p {
    font-size: 1rem;
  }
  .text-sm {
    font-size: 1rem;
  }
}

@import "./styles/colors.less";
@import "./styles/ui.less";
@import "./styles/landing.less";
@import "./styles/fonts.less";
@import "./styles/icons.less";
@import "./styles/auth.less";
@import "./styles/app.less";

@import "./styles/mobile.ui.less";
@import "./styles/mobile.index.less";

@import "./styles/mobile.landing";
@import "./styles/mobile.app";

@primary-color: #1890ff default !important; // primary color for all components
@bg-cabinet: linear-gradient(
  90deg,
  rgba(239, 248, 253, 1) 0%,
  rgb(203, 225, 247) 99%
);

@primary-color: #F95946;@layout-sider-background: transparent;@layout-header-background: transparent;@layout-body-background: transparent;@layout-footer-background: transparent;