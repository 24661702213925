@import "../../styles/colors";

.menu_item_card__wrapper {
  padding: 1rem;
  display: flex;
  border: 1px solid @app_divider_color;
  border-radius: 3px;
  .card__wrap {
    h3 { font-size: 0.6875rem; }
    p { font-size: 0.6875rem;}
    span { font-size: 0.6875rem;}
    h3, p { margin-bottom: 0.5rem; }
    &:first-child { flex: 3; }
    flex: 20;
    &:last-child { flex: 1; }
  }
}

@media (max-width: 720px) {
  .menu_item_card__wrapper {
    padding: 1rem;
    display: flex;
    border: 1px solid @app_divider_color;
    border-radius: 3px;

    .card__wrap {
      h3 {
        font-size: 1rem;
      }

      p {
        font-size: 1rem;
      }

      h3, p {
        margin-bottom: 0.75rem;
      }

      &:first-child {
        flex: 3;
      }

      flex: 16;

      &:last-child {
        flex: 1;
      }
    }
  }
}
@primary-color: #F95946;@layout-sider-background: transparent;@layout-header-background: transparent;@layout-body-background: transparent;@layout-footer-background: transparent;