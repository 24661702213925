@import '../../styles/colors';

.footer__wrapper {
  font-size: 0.875rem;
  background: @landing_footer_bg;
  min-height: 40vh;
  padding: 3.5rem 5rem;
  span {
    color: @landing_footer_link;
  }

  a {
    line-height: 1.2;
    color: @landing_footer_link;
    &:hover, &:focus {
      color: @landing_footer_link_hover;
    }
  }

  .link__header {
    color: @landing_footer_link_hover;
  }
  .links__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    a {
      margin-top: 1.5rem;
    }
  }
  .logo__wrapper {
    margin-bottom: 4.5rem;
  }

  .app__wrapper {
    margin-bottom: 2.5rem;
  }

  .icon__wrapper {
    a {
      margin-right: 1.25rem;
    }
    margin-bottom: 4rem;
  }
}

@media (max-width: 720px) {
  .footer__wrapper {
    padding: 1.5rem;
    .policy_link {
      margin-bottom: 1rem;
    }
  }
}
@primary-color: #F95946;@layout-sider-background: transparent;@layout-header-background: transparent;@layout-body-background: transparent;@layout-footer-background: transparent;