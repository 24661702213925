.landing__autocomplete {
  margin-top: 2.5rem;

  input {
    padding: 1rem 1.5rem;
    border-radius: 5px;
    outline: none;
    font-weight: 500;
    width: 40rem!important;
    border: 1px solid transparent;
    &:hover, &:focus {
      border-color: silver;
    }
  }
}

.base_app__autocomplete {
  .ant-select {
    width: 100%;
  }
  input {
    font-size: 0.875rem;
    border-radius: 5px;
    padding: 1rem;
  }
}

@media (max-width: 720px) {
  .landing__autocomplete {
    margin-top: 2.5rem;
    input {
      font-size: 1rem;
      padding: 0.75rem 1rem;
      width: 94vw !important;
    }
  }
}
@primary-color: #F95946;@layout-sider-background: transparent;@layout-header-background: transparent;@layout-body-background: transparent;@layout-footer-background: transparent;