@media (max-width: 720px) {
  .text-sm {
    font-size: 1rem;
  }

  // buttons
  .action__button {
    height: 3.5rem;
    padding: 1.5rem;
    &.search {
      padding: 1rem 4rem;
    }
    &.sign-up {
      height: 3rem;
    }
  }

  .action__button_lg {
    height: 3.5rem;
  }

  .mobile__fixed_bottom__wrapper {
    position: fixed;
  }
  .mobile__absolute_bottom__wrapper {
    position: absolute;
  }
  .mobile__fixed_bottom__wrapper, .mobile__absolute_bottom__wrapper {
    bottom: 0;
    display: flex;
    width: 100%;
    left: 0;
    padding: 1rem;
    padding-bottom: 2rem;
  }


  // Modals
  .mobile__full_screen_modal {
    position: relative;
    margin: 0;
    top: 0;
    padding-bottom: 0;
    .ant-modal-header {
      padding: 2.75rem 2.25rem;
      padding-bottom: 0;
    }
    &.ant-modal {
      max-width: 100vw!important;
    }
    .ant-modal-close-x {
      font-size: 1.75rem;
    }
    .ant-modal-content {
      height: 100%;
      margin-bottom: 1rem;
      overflow-y: scroll;
    }
  }

}



