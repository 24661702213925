.base_landing__header {
  padding: 0 5rem;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1;
  width: 100%;
  .auth__buttons {
    display: flex;
    justify-content: space-between;
    width: 200px;
    align-items: center;

    a {
      color: @color_nav_link;
    }
  }
  .auth__user_button {
    .auth__buttons;
    justify-content: flex-end;
  }
}

.base_landing__jubmbotron {
  position: relative;
  height: 60vh;
  padding: 2rem;
  background: rgba(20, 19, 19, 0.39);
  background-position: center !important;
  background-size: cover !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1, p {
    color: #ffff;
  }
  p {
    font-size: 1.125rem;
  }
  h1 {
    font-family: 'FilsonPro';
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }
}
