@import "../../styles/colors";

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 5rem;
  width: 100%;
  border-bottom: 1px solid @app_divider_color;
  &.no-border {
    border: none;
  }
}

.app_header {
  margin-bottom: 3rem;
  width: 100%;
  .app_header__wrapper {
    .header__wrapper;
    .app_header__wrap {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        justify-content: flex-end;
      }
      .app_header__delivery_choice {
        font-size: 0.9375rem;
        margin: 0 3rem;
        padding: 0.5rem 2rem;
        border-left: 1px solid @app_divider_color;
        border-right: 1px solid @app_divider_color;
        .or_text {
          color: @app_lighter_color;
          margin: 0 1rem;
        }
      }
      .app_header__location {
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        .coco__icon {
          margin-right: 1rem;
        }
      }
      .app_header__auth {
        a {
          padding: 0 1rem;
          &:first-child {
            border-right: 1px solid @app_divider_color;
          }
        }
      }
      .app_header__bucket {
        margin-left: 1rem;
        span {
          &:nth-child(2) {
            margin: 0 1rem;
          }
        }
        .action__button {
          padding: 0.75rem 1rem;
          height: 2.5rem;
          span {
            font-size: 0.975rem;
          };
        }
      }
    }
  }
}

.app_header__location {
  a {
    display: flex;
    align-items: center;
    &:hover, &:focus, &:active {
      span {
        &:after {
          background-color: @app_primary_color;
        }
        color: @app_primary_color;
      }
    }
  }
}

.address__popover_click_cover {
  position: absolute;
  width: 25rem;
  padding-bottom: 5rem;
  top: 5rem;
  height: 30vh;
  z-index: 99;
  .address__popover {
    padding: 1.375rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 19px 3px rgba(0, 0, 0, 0.1);
    background: white;
    input {
      width: 20rem !important;
    }

    h5 {
      font-weight: 700;
    }
  }
}
@media all and (max-width: 720px) {
  .app_header {
    margin-bottom: 0rem;
  }
  .app_header__wrapper {
    padding: 1.25rem 2rem!important;
  }
  .app_header__auth {
    align-items: center;
    width: 12rem;
    display: flex;
    justify-content: space-between;
  }

  .app_header {
    .app_header_mobile__location {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid @app_divider_color;
      padding: 1.5rem 2rem 2rem 2rem;
      span {
        font-size: 1.125rem;
        line-height: 1.25rem;
      }
      .mobile__link_wrapper {
        display: flex;
        flex-direction: column;
        .label {
          color: @app_medium_color;
          font-size: 0.875rem;
          margin-bottom: 0.625rem;
        }
      }
      .address__popover_click_cover {
        .address__popover {
          padding: 1.5rem 0;
        }
        position: absolute;
        width: 100%;
        padding-bottom: 5rem;
        left: 0;
        top: 5.25rem;
        height: 15vh;
        z-index: 99;
      }
    }
  }

}
@primary-color: #F95946;@layout-sider-background: transparent;@layout-header-background: transparent;@layout-body-background: transparent;@layout-footer-background: transparent;